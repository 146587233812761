import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import BackgroundImage from 'gatsby-background-image'
import Img from 'gatsby-image/withIEPolyfill';
import OptIn from '../components/optin'
import { RichText } from 'prismic-reactjs'

    export default ({ data }) => {
      const doc = data.prismic.home_page;
        return (

          <Layout>

            <div className="navbar">
              <div className="logoholder">
                <a href="#"><Img fixed={doc.logo_photoSharp.childImageSharp.fixed} alt="Homes54" /></a>
              </div>
            </div> 

            <div className="la">
                <div className="container">
                    <div className="optin-area">
                        <BackgroundImage
                          tag="div"
                          className="sixty"
                          fluid={doc.header_imageSharp.childImageSharp.fluid}
                          backgroundColor={`#ffffff`} >

                        <div className="secondaryphoto"><Img fluid={doc.secondary_photoSharp.childImageSharp.fluid} alt="" /></div>

                        </BackgroundImage>
                        <div className="forty">
                          <OptIn
                            photo={doc.profile_photoSharp.childImageSharp.fixed}
                            secondaryphoto={doc.secondary_photoSharp.childImageSharp.fluid}
                            name={doc.profile_name}
                            phone={doc.profile_phone}
                            email={doc.profile_email}
                            intro={doc.intro_text}
                          />
                        </div>
                    </div>
                    <div className="clear"></div>
                  </div>
              </div>
              
              <div className="boxed labox">
                <div className="boxed-container ulstyle latitle">
                    <div className="textwrapper">
                      {RichText.render(doc.title)}
                      {RichText.render(doc.main_content)}
                    </div>
                </div>
              </div>
                          
          </Layout>
    )
}

export const query = graphql`
  query LandingQuery {
        prismic {
          home_page(lang: "en-us", uid: "homepage") {
            title
            main_content
            header_image
            header_imageSharp {
              childImageSharp {
                fluid(maxWidth: 800, quality: 80) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            secondary_photo
            secondary_photoSharp {
              childImageSharp {
                fluid(maxWidth: 65, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            logo_photo
            logo_photoSharp {
              childImageSharp {
                fixed(quality: 90, width: 109) {
                  ...GatsbyImageSharpFixed_withWebp_noBase64
                }
              }
            }
            intro_text
            profile_name
            profile_email
            profile_phone
            profile_photo
            profile_photoSharp {
              childImageSharp {
                fixed(width: 100, quality: 85) {
                  ...GatsbyImageSharpFixed_withWebp
                }
              }
            }
            _meta {
              id
              uid
              type
            }
          }
        }
      }
    `