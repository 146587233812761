import React, { Component } from "react"
import Img from 'gatsby-image/withIEPolyfill';

export default class OptIn extends Component {
 
    constructor(props) {
      super(props);
  
      this.state = {
        formSubmitted: false,
      };
  
      this.onSubmit = this.onSubmit.bind(this);
    }
  
    onSubmit(event) {
      event.preventDefault();
      const data = new FormData(event.target);
  
      fetch('https://getform.io/f/5b0b0fbf-4aa7-4f81-b7d1-b666553a26ea', {
        method: 'POST',
        body: data,
        mode: 'no-cors',
      })
        .then(response => {
          this.setState({ formSubmitted: true });
          setTimeout(() => {
            this.setState({ formSubmitted: false });
          }, 3000);
        })
        .catch(error => console.log('Request failed', error));
    }
  
    render() {
      const { formSubmitted } = this.state;
      const { photo, name, phone, email, intro } = this.props;
        
      if (formSubmitted === true) {
        return (<h2 className="submitted">Thank you!<br />We will be in touch.</h2>)
    }


      return (
        <div>
          
          
          <div className="profile">
            <Img className="profilephoto" fixed={photo} alt="Jeannine Silun" />
            <div className="profilename">{name}</div>
            <div className="profilephone">{phone}</div>
            <div className="profileemail"><a href={"mailto:" + email}>{email}</a></div>
          </div>


          <div className="clear"></div>
          <div className="optintro">{intro}</div>
  
          { !formSubmitted && (
            <form onSubmit={this.onSubmit}>

              <label htmlFor="fullname" className="hidelabel">Enter your name:</label>
              <input
                id="fullname"
                type="text"
                name="fullname"
                placeholder="Enter your name..."
                className="inputclass"
                required
              />
              <label htmlFor="emailaddress" className="hidelabel">Enter your email:</label>
              <input
                id="emailaddress"
                type="text"
                name="email"
                placeholder="Enter your email..."
                className="inputclass"
                required
              />
              <input type="submit" name="submit" className="open-modal optinsubmit" value="Start a Conversation" />
            </form>
          )}
        </div>
      );
    }
  }