import React from 'react';

export default ({ children }) => (
        <div>    
            
        {children}
    
        <footer>

        <div className="copyrights">
            <ul>
                <li><strong>Homes54<sup>TM</sup></strong></li>
                <li>Copyright &copy;2020</li>
                <li>All Rights Reserved</li>
            </ul>
        </div>

        <div className="contact-info">
            <ul>
                <li><strong>Jeannine Silun</strong></li>
                <li>(508) 579-0696</li>
                <li><a href="mailto:jsilun@yahoo.com">jsilun@yahoo.com</a></li>
            </ul>
        </div>

    </footer>

</div>
  )